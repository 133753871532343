<script>
import Loading from '../general/Loading'
import NewContentCard from '../contents/NewContentCard'
import EmptyList from '@/components/general/EmptyList'
export default {
  name: 'HomepageUserEnrollment',
  components: { Loading, NewContentCard, EmptyList },
  data () {
    return {
      loading: true,
      enrolledCourses: []
    }
  },
  created () {
    this.$store.dispatch('attemptGetEnrolledCourses').then((data) => {
      this.enrolledCourses = data.course
      this.loading = false
    })
  },
  methods: {
    validateWorkspace () {
      if (this.selectedWorkspace.type === 'individual') {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'courses' } })
      } else if (this.selectedWorkspace.type === 'business') {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index', query: { companyDomain: this.selectedWorkspace.name } })
      }
    },
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    }
  }
}
</script>
<template>
 <div class="homepage-user-enrolled--container">
  <loading v-if="loading"></loading>
  <div style="padding-top: 20px" v-else>
    <div v-if="enrolledCourses.length && this.selectedWorkspace.type === 'individual'">
      <h5 style="padding-bottom: 20px">{{$t('individual:enrolled.courses')}}</h5>
      <div class="contents-list--list-wrapper" v-if="enrolledCourses.length">
        <new-content-card v-for="(enrolledCourse) in enrolledCourses" :key="enrolledCourse.id" :course="enrolledCourse"></new-content-card>
      </div>
    </div>
    <empty-list class="empty-list" @btnClick="validateWorkspace()" v-if="!enrolledCourses.length && this.selectedWorkspace.type === 'individual'"
      :title="$t('empty.state.title.enroll:empty.title')" :textOne="$t('empty.state.text:empty.text.enroll')"
      :image="'/assets/images/empty-box.png'" :buttonText="$t('empty.state.button:discovery.course')"
      :validateRole="true"></empty-list>
  </div>
</div>

</template>
<style lang="scss">
.homepage-user-enrolled--container {
  padding-bottom: 40px;
  .homepage-user-enrolled--banner__wrapper {
    text-align: left;
    padding: 50px 24px;
    > * {
      margin: 0;
    }
  }
}
  .contents-list--list-wrapper {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));

    &>div {
      width: 100%;
    }

    .content-card--container {
      width: 100%;
      margin: 0;
    }
  }
</style>
